export const sectors = {
  edu: 'Education',
  hsc_child: "H & SC Children's",
  hsc_adult: "H & SC Adult's",
  anz_edu: 'Education',
  anz_ss: 'Social Services',
  anz_health: 'Health',
  other: 'Other',
} as const

export const positions = {
  edu: [
    '1:1 TA (Support)',
    'Admin',
    'Assistant Headteacher',
    'Behaviour Lead',
    'Bursar',
    'Careers Advisor',
    'CEO',
    'Counsellor',
    'Cover Supervisor',
    'Deputy Headteacher',
    'Designated Safeguarding Lead',
    'Director',
    'Educational mental health practitioner',
    'Educational psychologist',
    'EYFS Practitioner',
    'HEAD OF School',
    'Head of Year',
    'Headteacher',
    'HLTA',
    'Inclusion Manager',
    'Learning mentor',
    'LSA',
    'Lunchtime Supervisor',
    'NQT',
    'Nursery Manager',
    'Nursery Nurse',
    'Nursery Worker',
    'Office Manager',
    'Pastoral Manager',
    'Safeguarding Lead',
    'School Business Manager',
    'Secretary',
    'SENCO',
    'Support Worker',
    'TA / Support Staff',
    'Teacher',
    'Training Co-ordinator',
    'Tutor',
    'Unqualified Teacher',
    'Volunteer',
    'Welfare Assistant',
    'Year Leader',
    'Training Administrator',
  ],

  hsc_child: [
    'Business Support',
    'Care Assistant',
    'Care Coordinator',
    'Carer',
    'Case Manager',
    'CEO',
    'Community Support Worker',
    'Compliance & Standards Manager',
    'Deputy Manager',
    'Director',
    'Director of Social Services',
    'Early Intervention & Protection',
    'Foster Carer',
    'Learning & Development Manager',
    'Night Care Assistant',
    'Night Officer',
    'Office Manager',
    'Operations Manager',
    'Owner',
    'Pastoral Manager',
    'Playleader',
    'Registered Manager',
    'Residential Support Worker',
    'Residential Worker',
    'RI - Responsible individual',
    'Secretary',
    'Service Manager',
    'Sports Coach',
    'Support Worker',
    'Team Manager',
    'Training & Development',
    'Training Administrator',
    'Training Co-ordinator',
    'Training Manager',
    'Unit Manager',
    'Volunteer',
    'Youth Offending Service',
    'Youth Worker',
  ],
  hsc_adult: [
    'Care Assistant',
    'Care Coordinator',
    'Case Manager',
    'CEO',
    'Compliance & Standards Manager',
    'Director',
    'Learning & Development Manager',
    'Night Care Assistant',
    'Night Officer',
    'Nursing Lead',
    'Office Manager',
    'Operations Manager',
    'Owner',
    'Pastoral Manager',
    'Registered Manager',
    'Residential Support Worker',
    'Residential Worker',
    'Secretary',
    'Support Worker',
    'Training & Development',
    'Training Co-ordinator',
    'Training Manager',
    'Unit Manager',
    'Volunteer',
    'Training Administrator',
    'RI - Responsible individual',
    'Deputy Manager',
    'Specialist Co-ordinator - Adults',
    'Team Manager',
  ],
  other: [
    'Currently Unemployed',
    'Recruitment Consultant',
    'Wellbeing Co-ordinator',
    'Workforce Development',
    'RI - Responsible individual',
  ],
} as const
