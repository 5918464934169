import { ThemeProvider } from '@mui/material/styles'
import * as Sentry from '@sentry/react'
import { Amplify } from 'aws-amplify'
import { PostHogProvider } from 'posthog-js/react'
import React from 'react'
import { createRoot } from 'react-dom/client'
import TagManager from 'react-gtm-module'
import { BrowserRouter } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'

import { AuthProvider } from '@app/context/auth'

import './i18n/config'

import App from './App'
import { ErrorPage } from './components/ErrorPage'
import theme from './theme'
import { AwsRegions } from './types'

import.meta.env.VITE_APP_VERSION
  ? console.info(`App version is ${import.meta.env.VITE_APP_VERSION}`)
  : null

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.extraErrorDataIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: Number(import.meta.env.VITE_SENTRY_TRACING_SAMPLE_RATE),
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  denyUrls: [/localhost/i, /127.0.0.1/],
  replaysSessionSampleRate: Number(
    import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
  ),
  replaysOnErrorSampleRate: Number(
    import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
  ),
})

if (window.location.host === 'localhost:4000') {
  // 👆👆 used to set up cognito for anz local development
  Amplify.configure({
    Auth: {
      region: import.meta.env.VITE_AWS_REGION,
      userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID_ANZ,
      userPoolWebClientId: import.meta.env.VITE_COGNITO_USER_POOL_CLIENT_ID_ANZ,
    },
  })
} else {
  Amplify.configure({
    Auth: {
      region: import.meta.env.VITE_AWS_REGION,
      userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
      userPoolWebClientId: import.meta.env.VITE_COGNITO_USER_POOL_CLIENT_ID,
    },
  })
}

TagManager.initialize({ gtmId: import.meta.env.VITE_GTM_ID })

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(document.getElementById('app')!)
const posthogApiKey =
  import.meta.env.VITE_AWS_REGION === AwsRegions.Australia
    ? import.meta.env.VITE_PUBLIC_POSTHOG_KEY_AU
    : import.meta.env.VITE_PUBLIC_POSTHOG_KEY

root.render(
  <React.StrictMode>
    <PostHogProvider
      apiKey={posthogApiKey}
      options={{
        api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
      }}
    >
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <QueryParamProvider
            adapter={ReactRouter6Adapter}
            options={{ updateType: 'replaceIn' }}
          >
            <Sentry.ErrorBoundary
              fallback={errorData => (
                <ErrorPage
                  errorData={errorData}
                  debug={
                    import.meta.env.VITE_SENTRY_ENVIRONMENT === 'development'
                  }
                />
              )}
            >
              <AuthProvider>
                <App />
              </AuthProvider>
            </Sentry.ErrorBoundary>
          </QueryParamProvider>
        </BrowserRouter>
      </ThemeProvider>
    </PostHogProvider>
  </React.StrictMode>,
)
